import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';

import { Store } from '@ngrx/store';

import * as fromApp from './stores/app.reducers';
import * as LoaderAction from './stores/loader/loader.actions';
import * as UserProfileActions from './stores/profile/user-profile.actions';
import { DetectLocationService } from './shared/services/detect-location.service';
import * as AuthActions from './stores/auth/auth.actions';
import _ from 'lodash';
import { filter } from 'rxjs/operators';
import ClevertapReact from 'clevertap-react';
import { LazyLoadingService } from './shared/services/lazy-loading.service';
import { LazyLoadFileImpl } from './shared/shared.module';
import { environment } from './../environments/environment';
import { BrowserGlobalsService } from 'browser-globals';
import { Location } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { EventDataBusService } from './shared/services/event-data-bus.service';
import { FiitJeeService } from './auth/fiit-jee-login/service/fiit-jee.service';
import { HelpGuideInfoService } from './help-guide-info.service';
declare const gtag: Function; // <------------Important: the declartion for gtag is required!


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ { provide: 'googleTagManagerId', useValue: environment.gtmId }]
})
export class AppComponent implements OnInit {
  title = 'mypat-fe-students';
  public authenticated: boolean = false;
  public showHeader: boolean = false;
  private isLogout: boolean = false;
  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private detectLocation: DetectLocationService,
    private eventDataBusService: EventDataBusService,
    private fiitJeeService: FiitJeeService,
    private helpGuideService: HelpGuideInfoService
  ) { }

  async asyncClevertapInitialize(){
    ClevertapReact.initialize(environment.clevertapId);
    return Promise.resolve()
  }
  async asyncAddGtmToDom() {
    this.gtmService.addGtmToDom();
    return Promise.resolve();
  }
  async asyncPushTag(gtmTag){
    this.gtmService.pushTag(gtmTag);
    return Promise.resolve();
  }
  async asyncGtmTag(){
    const a = (await import('angular-google-tag-manager')).GoogleTagManagerService;
    this.gtmService = new a(new BrowserGlobalsService, environment.gtmId);
    return Promise.resolve();
  }

  async ngOnInit() {
    localStorage.removeItem('cachedCourses');
    // test preview bug fix
    // const extractedAuthToken = window?.location?.href?.split('?authToken=')?.[1]?.split('&')?.[0];
    // if (extractedAuthToken) {
    //   sessionStorage.setItem('authToken', extractedAuthToken);
    //   const payload = {
    //     fromScreen: 'refresh',
    //     authToken: extractedAuthToken,
    //     tag: ''
    //   };
    //   this.store.dispatch(new AuthActions.SetToken(extractedAuthToken));
    //   this.store.dispatch(new AuthActions.SetAuthentication());
    // }
    // await this.asyncGtmTag();
    await this.asyncClevertapInitialize();
    // await this.lazyLoadingService.lazyLoad([
    //   new LazyLoadFileImpl('/assets/js/owl.carousel.min.js','script'),
    //   new LazyLoadFileImpl('/assets/css/owl.carousel.css', 'stylesheet')
    // ]).toPromise();
    this.pushGTMTag('page_view');
    //have to work on it.
    this.location.onUrlChange(() => {
      let path: string = location.pathname;
      path = path.trim();
      path = path.replace(/^\/|\/$/g, '');
      if(path.includes('home')){
        path = path.split('home/')[1] || path.split('home/')[0];
      }
      const url = path;
      if(path.charCodeAt(40) && !(path.charCodeAt(40) === 47 || path.charCodeAt(41) === 47)){
        path = path.substring(0,40);
        const pathWords = path.split('/');
        pathWords.splice(pathWords.length - 1, 1);
        path = pathWords.join('/');
      }
      this.pushGTMTag('page_view');
      this.pushGTMTag('custom_event', path, url);
    })
    await this.asyncAddGtmToDom();
    localStorage.removeItem('checkedConceptHistory');
    localStorage.removeItem('clickedConceptHistory');
    this.store.select('auth').subscribe((data) => {
      if (data.authenticated && (localStorage.getItem('authToken') || sessionStorage.getItem('authToken'))) {
        this.authenticated = data.authenticated;
        const payload = {
          fromScreen: 'refresh',
          authToken: localStorage.getItem('authToken') || sessionStorage.getItem('authToken'),
          tag: ''
        };
        if (window.location.href.includes('teacher-proforma') || window.location.href.includes('parent-proforma')) {
          if (window.location.href.includes('goal-setting')) {
            payload.tag = (window.location.href.includes('teacher-proforma') ? 'teacher' : 'parent')
            // setTimeout(() => {
              this.store.dispatch(new UserProfileActions.GetUserDetail(payload));
            // }, 500);
          }
        } else {
          // setTimeout(() => {
            this.store.dispatch(new UserProfileActions.GetUserDetail(payload));
          // }, 500);
        }
      } else {
        this.authenticated = false;
      }
      // const authToken = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
      // this.store.select('userProfile').subscribe(res => {
      //   const userData = res.userData;
      //   if (userData.source && userData.userId && userData.source === 'glorifire-jr' && authToken !== null && authToken !== undefined && !location.href.includes('logout')) {
      //     const glorifireJrLink = environment?.glorifireJr;
      //     window.open(`${glorifireJrLink}?authToken=${authToken}`, "_self")
      //   }
      // });
    });
    this.router.events.subscribe((event) => {
      const redirectPdf = localStorage.getItem('redirectUrl');
      if(redirectPdf){
        localStorage.removeItem('redirectPdf');
        localStorage.setItem('lastRequested',`/learn/pdf/${redirectPdf}`);
        window.location.href = `${environment.glorifireUrl}/learn/pdf/${redirectPdf}`;
      }
      if (window.location.href?.includes('logout') && !this.isLogout) {
        if (localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) {
          this.store.dispatch(new AuthActions.Logout());
          localStorage.clear();
          sessionStorage.clear();
        }
        else {
          window.location.replace('');
        }
        this.isLogout = true;
      }
      if (event instanceof RouteConfigLoadStart) {
        this.store.dispatch(new LoaderAction.SetLoader(true));
      } else if (event instanceof RouteConfigLoadEnd) {
        this.store.dispatch(new LoaderAction.SetLoader(false));
      } else if (event instanceof NavigationEnd) {
        this.store.dispatch(new LoaderAction.SetLoader(false));
        this.checkHeaderCondition(event);
        this.checkFooterCondition(event);
        // if (this.detectLocation.isFiitjeeDomain()) {
        //   this.router.navigate(['/auth/sign-in']);
        // }
      } else if (event instanceof NavigationCancel) {
        this.store.dispatch(new LoaderAction.SetLoader(false));
        this.checkHeaderCondition(event);
        this.checkFooterCondition(event);
      }
    });
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length !== 0 && params?.enrollmentNo) {
        this.location.replaceState('/');
        this.fiitJeeService.fetchRedirection({enrollmentno: params?.enrollmentNo, registrationNo: ''});
        return;
      }
      if (!(_.isEmpty(params)) && params['authToken'] && !params['process'] && !this.router.url.includes('community')) {
        localStorage.removeItem('hideMyplanPopup');
        this.store.dispatch(new LoaderAction.SetLoader(true));
        sessionStorage.setItem('authToken', params['authToken']);
        if(params['redirectPdfUrl']) {
          localStorage.setItem('redirectPdf',params['redirectPdfUrl']);
        }
        this.store.dispatch(new AuthActions.SetToken(params['authToken']));
        this.store.dispatch(new AuthActions.SetAuthentication());
        this.store.dispatch(new LoaderAction.SetLoader(false));
        if (params['redirectPdfUrl']) {
          setTimeout(() => {
            localStorage.removeItem('redirectPdf');
            localStorage.setItem('lastRequested',`/learn/pdf/${params['redirectPdfUrl']}`);
            window.location.href = `${environment.glorifireUrl}/learn/pdf/${params['redirectPdfUrl']}`;
          },2000);
          return;
        }
        const redirectionUrl = params?.navigateTo || params?.redirectTo;
        if (params.loginVia && params.loginVia === 'qrcode') {
          localStorage.setItem('attemptIdQRCode', params['attemptId']);
          this.router.navigate(['/cpp-subjective-file-upload']);
        } else if (params.resultData) {
          sessionStorage.setItem('teachersRedirectUrl', params.redirectUrl);
          const resultData = JSON.parse(params.resultData);
          const url = '/test-results' + '/' + resultData.testId + '/' + resultData.attemptId + '/' +
          resultData.testType + '/' + (resultData.testName || resultData.encodedTestName);
          this.router.navigateByUrl(url);
        } else if (params.testId) {
          if (localStorage.getItem('testPreviewFromQuery'))
            localStorage.removeItem('testPreviewFromQuery');
          const { courseId, testId, goal, testPreview } = params;
          const url = `/upcoming-tests/test-details/course/${courseId}/test/${testId}?goal=${goal}&testPreview=${testPreview}`;
          this.router.navigateByUrl(url);
        }  else if (redirectionUrl) {
          localStorage.setItem('lastRequested', redirectionUrl);
          this.router.navigate([redirectionUrl]);
        } else {
          this.router.navigate(['/home']);
        }
      }

    });
    this.detectLocation.getMyCurrentLocation();
    this.eventDataBusService.hideHeader.subscribe((res) => {
      this.showHeader = !res;
    });
  }

  pdfRedirectionCheck() {
        let currentUrl = window.location.href;
        const tokenCheck = currentUrl?.split('?')[1]?.split('=')?.[0];
        if (tokenCheck !== 'authToken') {
          return;
        }
        let currentToken = currentUrl?.split('?')[1]?.split('=')?.[1];
        let pdfId = currentUrl?.split('?')[0]?.split('/')?.slice(-1)?.[0];
        this.helpGuideService.pdfId = pdfId;
        localStorage.setItem('authToken',currentToken);
        sessionStorage.setItem('authToken', currentToken);
        this.store.dispatch(new AuthActions.SetToken(currentToken));
        this.store.dispatch(new AuthActions.SetAuthentication());
        this.store.dispatch(new LoaderAction.SetLoader(false));
        this.showHeader = false;
  }

  pushGTMTag(event, path = '', url = ''){
    let gtmTag: any = {
      event: event
    };
    if(event === 'custom_event'){
      gtmTag = {
        event: 'custom_event',
        custom_event_name: path,
        url: url,
      };
    }
    gtmTag.userId = window.localStorage.getItem('enrollmentNo') || 'undefined';
      const centreIds =
        window.localStorage.getItem('centres')?.split(',') || [];
      gtmTag.centre1 = centreIds[0] || 'undefined';
      gtmTag.centre2 = centreIds[1] || 'undefined';
      const batchIds = window.localStorage.getItem('batches')?.split(',') || [];
      gtmTag.batch1 = batchIds[0] || 'undefined';
      gtmTag.batch2 = batchIds[1] || 'undefined';
      gtmTag.batch3 = batchIds[2] || 'undefined';
    this.gtmService.pushTag(gtmTag);
  }

  checkHeaderCondition(event): void {
    if (this.helpGuideService.pdfId) {
      this.showHeader = false;
      return;
    }
    let timeout = location.pathname.includes('test-details') ? 0 : 400;
    const routesToShowHeader = [
      '/terms-of-use',
      '/privacy-policy',
      '/profile',
      '/dashboard',
      '/refer-and-earn',
      '/home',
      '/community/view',
      '/view-schedule',
      '/concept-history',
      '/my-revision-plan',
      'revision/my-revision-plan',
      '/about-us',
      '/exam-temparament-questions',
      '/live-classes',
      '/copyright',
      '/disclaimer',
      '/fiitjee-recorded-lectures-privacy-policy',
      '/idea',
      '/help',
       "/manage-devices"
    ];
    setTimeout(() => {

      if (
        (window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')) &&
        window.location.pathname === '/home'
      ) {
        routesToShowHeader.push('/');
      } else if (
        ! (window.localStorage.getItem('authToken') || window.sessionStorage.getItem('authToken')) &&
        window.location.pathname === '/home'
      ) {
        return;
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < routesToShowHeader.length; i++) {
        if (event.url.includes(routesToShowHeader[i])) {
          // if (this.detectLocation.isFiitjeeDomain() && event.url === '/') {
          //   this.showHeader = false;
          // } else {
          //   this.showHeader = true;
          // }
          this.showHeader = true;
          break;
        } else {
          this.showHeader = false;
        }
      }
    }, timeout);
  }

  checkFooterCondition(event): void {

  }
}
